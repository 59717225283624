/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import lottie from "lottie-web"
import AnimatedWhatsapp from "../animations/whatsapp.json"
import PropTypes from "prop-types"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { BiChevronsUp } from "react-icons/bi"
import SimpleReactLightbox from "simple-react-lightbox"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../components/header"
import Offcanvas from "../components/offcanvas"
import "./layout.css"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Ajmatova from "../assets/ajmatova.svg"
// import { GrFacebook, GrInstagram } from "react-icons/gr"
import { CgInstagram } from "react-icons/cg"
import SEO from "../components/seo"
import { RiFacebookBoxLine, RiMailLine } from "react-icons/ri"
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#animated-whatsapp"),
      animationData: AnimatedWhatsapp,
    })
  }, [])

  const intl = useIntl()

  return (
    <>
      <SEO lang={intl.locale} />
      <Header siteTitle={data.site.siteMetadata.title || `Title`} />
      <div id="top" aria-hidden="true"></div>
      <Offcanvas />
      <div className="mx-auto">
        <SimpleReactLightbox>
          <main className="min-h-screen ">{children}</main>
        </SimpleReactLightbox>

        <footer className="relative py-12 pb-0 text-center bg-gray-100">
          <Social>
            <a
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Ir al facebook"
              title="Ir al facebook"
              href="https://www.facebook.com/Centro-de-estudios-Ajm%C3%A1tova-103420021349830/"
            >
              <RiFacebookBoxLine className="mx-3 text-5xl text-black" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Ir al Instagram"
              title="Ir al Instagram"
              href="https://www.instagram.com/centroestudiosajmatova/"
            >
              <CgInstagram className="mx-3 text-5xl text-black" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Contactanos por Mail"
              title="Contactanos por Mail"
              href="mailto:centrodeestudiosajmatova@gmail.com"
            >
              <RiMailLine className="mx-3 text-5xl text-black" />
            </a>
          </Social>
          <Nav>
            <Link
              activeClassName="active"
              className="mr-3 text-base font-bold tracking-wider text-black"
              partiallyActive={true}
              to="/siele"
            >
              SIELE
            </Link>
            <Link
              activeClassName="active"
              className="mr-3 text-base font-bold tracking-wider text-black"
              partiallyActive={true}
              to="/cursos"
            >
              <FormattedMessage id="courses" />
            </Link>
            <Link
              activeClassName="active"
              className="mr-3 text-base font-bold tracking-wider text-black"
              partiallyActive={true}
              to="/talleres"
            >
              <FormattedMessage id="workshops" />
            </Link>

            <Link
              activeClassName="active"
              className="mr-3 text-base font-bold tracking-wider text-black"
              partiallyActive={true}
              to="/blog"
            >
              Blog
            </Link>
            <Link
              activeClassName="active"
              className="mr-3 text-base font-bold tracking-wider text-black"
              partiallyActive={true}
              to="/about"
            >
              <FormattedMessage id="about" />
            </Link>
            <Link
              activeClassName="active"
              className="text-base font-bold tracking-wider text-black "
              partiallyActive={true}
              to="/contact"
            >
              <FormattedMessage id="contact" />
            </Link>
          </Nav>

          <Ajmatova className="h-32 mx-auto mt-6 mb-12 " />

          <div className="inline-block px-24 py-2 font-mono text-sm text-black hover:opacity-100 opacity-70">
            © {new Date().getFullYear()} realizado por
            <a
              target="_blank"
              className="inline-block ml-1"
              rel="noreferrer"
              href="https://www.santuan.com.ar"
            >
              stn
            </a>
          </div>
        </footer>
      </div>
      <AnchorLink
        tw="fixed bottom-0 left-0 z-50 p-4 m-4 font-mono text-2xl text-black bg-gray-100 rounded-full hover:text-gray-300 hover:bg-gray-800"
        href="#top"
        aria-hidden="true"
      >
        <BiChevronsUp />
      </AnchorLink>
      {/* <a
        tw="fixed bottom-0 right-0 z-50 p-1 mb-3 mr-3 overflow-hidden transition-all duration-500 rounded-full hover:bg-green-600 hover:text-black"
        target="_blank"
        rel="noopener noreferrer"
        title="Consultas por Whatsapp"
        data-tip="Consultas por Whatsapp"
        href="https://api.whatsapp.com/send?phone=+549%201126635233&text=%C2%A1Hola!%0DOlá!%0DHello%0DПривет%0D%F0%9F%A4%97%20"
      >
        <div id="animated-whatsapp" style={{ width: 60, height: 60 }} />
      </a> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Nav = styled.nav`
  ${tw`flex flex-col justify-center pt-2 pb-12 md:flex-row`}
  a {
    ${tw`py-2 mx-6 font-mono`}
  }
`

const Social = styled.div`
  ${tw`flex justify-center pt-2 pb-12`}
  a {
    ${tw`text-black`}
  }
`
