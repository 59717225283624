import { Link, FormattedMessage } from "gatsby-plugin-intl"
import React from "react"

const Navigation = ({ closeMenu }) => (
  <nav className="flex flex-col w-full">
    <Link
      onClick={closeMenu}
      activeClassName="opacity-70"
      aria-label="Ir al inicio"
      to="/"
      className="py-2 mb-2 font-serif text-lg text-white border-b border-gray-500 cursor-pointer hover:text-gray-500"
    >
      Inicio
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-70"
      aria-label="Ver la Galería"
      to="/cursos"
      className="py-2 mb-2 font-serif text-lg text-white border-b border-gray-500 cursor-pointer hover:text-gray-500"
    >
      <FormattedMessage id="courses" />
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-70"
      aria-label="Ver catetoria SIELE"
      to="/siele"
      className="py-2 mb-2 font-serif text-lg text-white border-b border-gray-500 cursor-pointer hover:text-gray-500"
    >
      SIELE
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-70"
      aria-label="Ver la Galería"
      to="/talleres"
      className="py-2 mb-2 font-serif text-lg text-white border-b border-gray-500 cursor-pointer hover:text-gray-500"
    >
      <FormattedMessage id="workshops" />
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-70"
      aria-label="Ver la Galería"
      to="/blog"
      className="py-2 mb-2 font-serif text-lg text-white border-b border-gray-500 cursor-pointer hover:text-gray-500"
    >
      Blog
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-70"
      aria-label="Ver la Galería"
      to="/about"
      className="py-2 mb-2 font-serif text-lg text-white border-b border-gray-500 cursor-pointer hover:text-gray-500"
    >
      <FormattedMessage id="about" />
    </Link>
    <Link
      onClick={closeMenu}
      activeClassName="opacity-70"
      aria-label="Ver las bandas"
      to="/contact"
      className="py-2 mb-2 font-serif text-lg text-white border-b border-gray-500 cursor-pointer hover:text-gray-500"
    >
      <FormattedMessage id="contact" />
    </Link>
  </nav>
)

export default Navigation
