import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import "./Language.css"
import styled from "@emotion/styled"
import tw from "twin.macro"
import Esflag from "../assets/arflag.svg"
import Brflag from "../assets/brflag.svg"
//import Enflag from "../assets/enflag.svg"

const languageName = {
  es: { name: "Español", icon: <Esflag/> },
  pt: { name: "Portuguese", icon: <Brflag/> },
}

const Language = () => (
  <LanguageSwitcher className="">
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map(language => (
          <button
            key={language}
            onClick={() => changeLocale(language)}
            style={{
              opacity: currentLocale === language ? `1` : `.5`,
              margin: 10,
              cursor: `pointer`,
            }}
            aria-label="Modificar Lenguage"
            className="transition-all duration-300 transform outline-none focus:outline-none languageSwitcher"
          >
            <div className="w-8 mt-2 h-7">
              {languageName[language].icon}
            </div>
            <span className="hidden text-sm font-semibold text-white">
              {languageName[language].name}
            </span>
          </button>
        ))
      }
    </IntlContextConsumer>
  </LanguageSwitcher>
)

export default Language

const LanguageSwitcher = styled.div`
  ${tw`flex justify-center font-mono`}

  .languageSwitcher {
    button {
      outline: 0 none !important;
    }

    &:hover {
      opacity: 1 !important;
    }
  }
`



