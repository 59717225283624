import PropTypes from "prop-types"
import React from "react"
import Language from "./Language.js"
import Headroom from "react-headroom"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import "./header.css"
import Ajmatova from "../assets/ajmatova.svg"

const Header = ({ siteTitle }) => (
  <Headroom disableInlineStyles>
    <HeaderContainer>
      <InnerHeader>
        <InnerLogo>
          <Logo>
            <Link
              to="/"
              aria-label="Centro de estudios Ajmátova"
              className="flex items-center font-serif text-lg text-white duration-200"
            >
              <Ajmatova className="h-16 mr-3" />
              <span className="w-40 leading-5 md:w-32">{siteTitle}</span>
            </Link>
          </Logo>
        </InnerLogo>

        <Nav>
          <Link
            activeClassName="active"
            className="mr-2 text-sm font-bold tracking-wider text-white md:text-base"
            partiallyActive={true}
            to="/siele"
          >
            SIELE
          </Link>
          <Link
            activeClassName="active"
            className="mr-2 text-sm font-bold tracking-wider text-white md:text-base"
            partiallyActive={true}
            to="/cursos"
          >
            <FormattedMessage id="courses" />
          </Link>
          <Link
            activeClassName="active"
            className="mr-2 text-sm font-bold tracking-wider text-white md:text-base"
            partiallyActive={true}
            to="/talleres"
          >
            <FormattedMessage id="workshops" />
          </Link>

          <Link
            activeClassName="active"
            className="mr-2 text-sm font-bold tracking-wider text-white md:text-base"
            partiallyActive={true}
            to="/blog"
          >
            Blog
          </Link>
          <Link
            activeClassName="active"
            className="mr-2 text-sm font-bold tracking-wider text-white md:text-base"
            partiallyActive={true}
            to="/about"
          >
            <FormattedMessage id="about" />
          </Link>
          <Link
            activeClassName="active"
            className="text-sm font-bold tracking-wider text-white md:text-base "
            partiallyActive={true}
            to="/contact"
          >
            <FormattedMessage id="contact" />
          </Link>
        </Nav>
        <div className="hidden pr-12 lg:block md:ml-4 lg:pr-0">
          <Language />
        </div>
      </InnerHeader>
    </HeaderContainer>
  </Headroom>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Header)

const Nav = styled.nav`
  ${tw`items-center justify-end hidden w-full font-sans lg:pr-6 lg:flex`}
`

const HeaderContainer = styled.header`
  ${tw`z-50 px-2 py-0 md:py-2`}
`

const InnerHeader = styled.div`
  ${tw`flex items-center justify-start w-full max-w-6xl px-0 py-3 m-auto lg:justify-start sm:pr-6 md:pr-0 lg:py-1`}
`

const InnerLogo = styled.div`
  ${tw`m-0 md:pl-0`}
`

const Logo = styled.div`
  ${tw`m-0 md:pl-0`}
`
